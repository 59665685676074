<template>
  <b-card no-body>
    <SidebarSearch :components="searchComponent" @search="searchData" />

    <TableHeader
      :selected-length="selectedDatas.length"
      :per-page="perPage"
      :has-create-button="false"
      :disable-delete="true"
      :disable-filter="(isLoading || isFetching) && tableData.length === 0"
      @per-page-change="perPage = $event"
    >
      <template #dropdown-items>
        <b-dropdown-item :disabled="selectedDatas.length === 0">
          <Feather-icon icon="CheckIcon" class="text-success mr-1" />
          Terima Pesanan ({{ selectedDatas.length }})
        </b-dropdown-item>
        <!-- <b-dropdown-item :disabled="selectedDatas.length === 0">
          <Feather-icon icon="LinkIcon" />
          Tautkan SKU ({{ selectedDatas.length }})
        </b-dropdown-item> -->
      </template>
    </TableHeader>

    <b-skeleton-table
      v-if="isLoading"
      :rows="4"
      :columns="7"
      :table-props="{ borderless: true, striped: true, small: true }"
    />

    <b-table-simple v-else responsive striped small style="zoom: 83%">
      <b-thead>
        <b-tr>
          <b-th class="bg-transparent" style="width: 6%">
            <b-form-checkbox
              v-model="selectAll"
              size="md"
              @change="selectAllRows"
            />
          </b-th>
          <b-th
            v-for="(column, index) in tableColumns"
            :key="index"
            :class="[column.class]"
            :style="{ width: column.style }"
          >
            {{ column.label }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-if="tableData.length > 0">
        <b-tr
          v-for="(data, index) in tableData"
          :key="index"
          :style="
            data.productList.length < 1
              ? 'opacity: 0.5; pointer-events: none; user-select: none; border: 2px dashed red; background-color: #f8d7da; cursor: not-allowed;'
              : ''
          "
        >
          <b-td>
            <b-form-checkbox v-model="selectedDatas" :value="data" size="md" />
          </b-td>
          <b-td>
            <b-img
              :src="
                require('@/assets/images/marketplace/' +
                  data.marketplaceType +
                  '.png')
              "
              :alt="data.marketplaceName"
              style="width: 24px; height: 24px"
            />
            {{ data.marketplaceName ? data.marketplaceName : 'Toko Dummy' }}
          </b-td>
          <b-td>
            <div class="d-flex flex-column">
              <div class="my-1">
                <b-badge variant="primary" class="mr-1">
                  {{
                    marketplaceAbbreviation(data.marketplaceType) +
                    '-' +
                    (data.orderId ? data.orderId : '-')
                  }}
                </b-badge>
                <b-badge class="mr-1">
                  {{ formatDateWithTime(data.createdDate) }}
                </b-badge>
              </div>
              <div>
                <div class="d-flex flex-column">
                  <b> List Produk :</b>
                  <b-table-simple borderless>
                    <b-tbody>
                      <b-tr
                        v-for="(product, productIndex) in data.productList"
                        :key="productIndex"
                      >
                        <b-td>
                          <div class="d-flex flex-column">
                            <div class="d-flex align-items-center">
                              <b-img
                                width="60"
                                :src="
                                  product.image
                                    ? product.image
                                    : 'https://cdn.statically.io/avatar/' +
                                      product.productName
                                "
                                :alt="'product #' + productIndex"
                                thumbnail
                                class="mr-2"
                              />
                              <div class="flex-grow-1">
                                <h6 class="text-primary">
                                  <b>{{ product.productName }}</b>
                                </h6>

                                <div class="d-flex flex-column mb-1">
                                  <!-- productId -->
                                  <small>
                                    <b> ID Produk :</b>
                                    {{
                                      product.productId
                                        ? product.productId
                                        : '-'
                                    }}
                                  </small>
                                  <!-- SKU -->
                                  <small>
                                    <b>SKU :</b>
                                    {{ product.sku ? product.sku : '-' }}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <div class="d-flex flex-column">
                    <span class="line-dashed" />
                    <p class="mt-1 mb-0 p-0">
                      Nama Penerima :
                      {{ data.recipientName ? data.recipientName : '-' }}
                    </p>
                    <p class="m-0 p-0">
                      Nomor Resi :
                      {{ data.trackingNumber ? data.trackingNumber : '-' }}
                    </p>
                    <p class="m-0 p-0">
                      Kurir :
                      {{ data.courierName }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </b-td>
          <b-td class="text-right">
            {{ formattedCurrency(data.totalPrice) }}
          </b-td>
          <b-td class="text-center">{{ formattedNumber(data.quantity) }}</b-td>
          <!-- <b-td class="text-center">{{ data.courierName }}</b-td> -->
          <!-- button untuk terima dan tolak -->
          <!-- <b-td class="text-center">
            <b-button
              v-if="
                data.marketplaceType === 'tokopedia' ||
                data.marketplaceType === 'lazada'
              "
              :disabled="data.productList.length < 1"
              variant="info"
              size="sm"
              block
              class="d-flex justify-content-between align-items-center"
            >
              <span>Cetak AWB</span>
              <font-awesome-icon :icon="['fas', 'file-alt']" />
            </b-button>

            <b-button
              v-if="data.marketplaceType === 'lazada'"
              :disabled="data.productList.length < 1"
              variant="success"
              size="sm"
              block
              class="d-flex justify-content-between align-items-center"
            >
              <span>Konfirmasi Pengiriman</span>
              <font-awesome-icon :icon="['fas', 'truck']" />
            </b-button>
          </b-td> -->
        </b-tr>
      </b-tbody>
      <EmptyTableRow v-else :colspan="tableColumns.length + 1" />
    </b-table-simple>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BDropdownItem,
  BButton,
  BFormCheckbox,
  BTableSimple,
  BThead,
  BTbody,
  BTh,
  BTr,
  BTd,
  BImg,
  BBadge,
  BSkeletonTable,
} from 'bootstrap-vue';
import { defineComponent } from '@vue/composition-api';
import SidebarSearch from '@/components/SidebarSearch.vue';
import TableHeader from '@/components/TableHeader.vue';
import EmptyTableRow from '@/components/EmptyTableRow.vue';
import axios from '@axios';

export default defineComponent({
  components: {
    BRow,
    BCol,
    BCard,
    SidebarSearch,
    TableHeader,
    EmptyTableRow,
    BDropdownItem,
    BButton,
    BFormCheckbox,
    BTableSimple,
    BThead,
    BTbody,
    BTh,
    BTr,
    BTd,
    BImg,
    BBadge,
    BSkeletonTable,
  },
  async created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  async mounted() {
    await this.fetchData();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    formattedCurrency() {
      return price =>
        new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(price);
    },
    formattedNumber() {
      return stock => new Intl.NumberFormat('id-ID').format(stock);
    },
    formatDateWithTime() {
      return date => {
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        };

        return new Date(date).toLocaleDateString('id-ID', options);
      };
    },
    marketplaceAbbreviation() {
      return marketplace => {
        if (marketplace === 'tokopedia') {
          return 'TKD';
        }
        if (marketplace === 'shopee') {
          return 'SHP';
        }
        if (marketplace === 'lazada') {
          return 'LZD';
        }

        return 'BLB';
      };
    },
  },
  data() {
    return {
      tableColumns: [
        {
          label: 'nama toko',
          class: 'bg-transparent text-center',
          style: '15%',
        },
        {
          label: 'Produk dan Data Pesanan',
          class: 'bg-transparent text-center',
          style: '50%',
        },
        {
          label: 'Total Harga',
          class: 'bg-transparent text-center',
          style: '',
        },
        {
          label: 'QTY',
          class: 'bg-transparent text-center',
          style: '5%',
        },
      ],
      tableData: [],
      currentPage: 1,
      perPage: 10,
      isLoading: true,
      totalRows: 0,
      isFetching: false,
      selectedDatas: [],
      selectAll: false,
      search: '',
      productData: {},
    };
  },
  methods: {
    async fetchData() {
      if (this.tableData.length > 1) {
        if (this.tableData.length >= this.totalRows) {
          return;
        }
      }

      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        status: 'SHIPPING',
        showloading: 0,
      };

      if (this.search) {
        if (this.search.search) {
          params.search = this.search.search;
        }
      }

      const url = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');

      const response = await axios.get(`order?${url}`);

      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        this.tableData = [...this.tableData, ...response.data.data];
      }

      this.totalRows = response.data.total;
      this.currentPage += 1;
      this.isLoading = false;
    },

    async searchData(search = null) {
      this.search = search;
      this.tableData = [];
      this.currentPage = 1;
      this.totalRows = 0;

      await this.fetchData();
    },

    handleScroll() {
      const isAtBottom =
        document.documentElement.scrollHeight -
          (window.innerHeight + window.scrollY) <=
        1;

      if (isAtBottom && !this.isFetching) {
        this.isFetching = true;

        this.fetchData();

        setTimeout(() => {
          this.isFetching = false;
        }, 1800);
      }
    },

    selectAllRows() {
      if (this.selectAll) {
        this.selectedDatas = this.tableData;
      } else {
        this.selectedDatas = [];
      }
    },
  },
});
</script>

<style lang="scss">
.line-dashed {
  border-bottom: 1px dashed #e0e0e0;
}
</style>
